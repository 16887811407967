import React from "react"
import Image from 'gatsby-image'
import { useStaticQuery, graphql } from "gatsby"
import { ButtonCTA, ButtonPhone } from './utils/buttons'
import { IconCheck, IconPhone } from "./utils/fa-Icons"
import HeroForm from "./HeroForm"
import { AppContext } from '../context/AppContext'
import { useContext } from "react"

const Header = () => {
  const { state, dispatch } = useContext(AppContext);

  const data = useStaticQuery(graphql`
    {
      logo:file(relativePath: {eq: "logo.png"}) {
        childImageSharp {
          fluid(maxWidth:148) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hero:file(relativePath: {eq: "poster.jpg"}) {
        childImageSharp {
          fluid( quality:100, maxWidth:480){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      badge:file(relativePath: {eq: "IWT_Badge-small.webp"}) {
        childImageSharp {
          fluid(quality:50) {
            ...GatsbyImageSharpFluid
          }
        }
      }, 
    }
  `)
  const {
    logo: { childImageSharp: { fluid: logo } },
    hero: { childImageSharp: { fluid: hero } },
    badge: { childImageSharp: { fluid: badge } } } = data
  return (
    <React.Fragment>
      <header className='header'>
        <div className="header__banner">
          <div className="header__banner--item1">
            <Image loading='eager' fluid={logo} style={{ maxWidth: '148px', textAlign: 'center' }} />
          </div>

          <div className="header__banner--item2">
            <a href="tel:+44 1617 102771" className="contactLink">
              <h3>Learn the 4DYNAMICS today!</h3>
              <h4>+44 161 710 2771</h4>
            </a>
          </div>

          <div className="header__banner--item3">
            <ButtonCTA href='#form-request-course-information' fontSize={1.6} round grow href='#form-request-course-information'>Request course information NOW</ButtonCTA>
          </div>
          <div className="header__banner--item4">
            <Image loading='eager' fluid={badge} style={{ maxWidth: '152px' }} />
          </div>
          <div className="header__banner--item5">
            <ButtonPhone href='tel:+44%20161%20710%202771' fontSize={2} round grow style={{ height: `50px`, width: `50px`, color: `#fff` }}><IconPhone /></ButtonPhone>
          </div>
        </div>
        <section className="row">
          <h1>Want to learn how to trade?</h1>
          <h2>Get started today with our online mentors</h2>
        </section>
        <section className='row jumbotron'>
          <article className="box--2">
            <HeroForm />
          </article>

          <article className="box--1">
            <a className='video-link' onClick={() => { dispatch({ type: "OPEN_VIDEO", payload: { isOpen: true, videoUrl: "https://videos.sproutvideo.com/embed/ac9cd8b61c1fe1c625/0ee3288fc91be02a?playerColor=ff5a28&autoplay=true&lightbox=true" } }) }} ><Image fluid={hero} /></a>
            <h2>Learn how to trade <span className='orange'>FOREX</span> and more</h2>
            <p>Our courses are specifically for people who are (or want to be) traders.</p>
            <ul className='jumbotron__ul'>
              <li><IconCheck /> Learn how to identify opportunities and execute trading strategies.</li>
              <li><IconCheck />Get mentored by pro-traders, analysts, strategists and hedge fund managers.</li>
              <li><IconCheck />Learn trading quickly from beginner to advanced levels.</li>
              <li><IconCheck />Become trading certified and learn how to grow a consistent income.</li>
            </ul>
          </article>

        </section>
      </header>
    </React.Fragment >
  )
}

export default Header
