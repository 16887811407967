import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck, faPhone, faGlobe, faLocationArrow, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons'


const style = { position: 'absolute', marginLeft: "-2em", color: "#ff5a28" }
// const style2 = { color: "#fff" }

export const IconCheck = () => <span style={style}> <FontAwesomeIcon icon={faCheck}></FontAwesomeIcon></span>
export const IconPhone = () => <span > <FontAwesomeIcon icon={faPhone}></FontAwesomeIcon></span>

export const IconFacebook = () => <FontAwesomeIcon icon={faFacebook}></FontAwesomeIcon>
export const IconTwitter = () => <FontAwesomeIcon icon={faTwitter}></FontAwesomeIcon>
export const IconLinkedIn = () => <FontAwesomeIcon icon={faLinkedin}></FontAwesomeIcon>
export const IconGlobe = () => <FontAwesomeIcon icon={faGlobe}></FontAwesomeIcon>

export const IconLocationArrow = () => <FontAwesomeIcon icon={faLocationArrow}></FontAwesomeIcon>
export const IconEnvelope = () => <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>