import React from 'react'
import styles from './main.module.scss'
import { IconCheck } from "./utils/fa-Icons"
import { ButtonCTA } from './utils/buttons'
import LazyLoad from 'react-lazyload'
import Helmet from 'react-helmet'

const OurCourses = () => {
  return (
    <section className='row our-courses'>
      <LazyLoad>
        <Helmet>
          <script src={`//js.hs-scripts.com/7503394.js`} async defer />
        </Helmet>
      </LazyLoad>
      <h2 className={styles.header2}>Our <span className='orange'>courses</span> are for you if;</h2>
      <div className={styles.wrapper}>

        <article className="our-courses__col">
          <ul className={styles.ul}>
            <li><IconCheck />You want to earn a living by trading the markets with the best systems and education available in the industry, delivered by best in class mentors, traders and trainers.</li>
            <li><IconCheck />You crave mentor-ship from time tested professional traders and hedge fund managers, that already have the trading and wealth answers you are searching for.</li>
            <li><IconCheck />You need to know where and how the high volume traders and market makers are trading their money.​</li>
            <li><IconCheck />You require access to systems and software that give you a clear advantage over the 1000's of traders that still rely on the same old tired technical analysis.</li>
          </ul>
        </article>
        <article className="our-courses__col">
          <ul className={styles.ul}>
            <li><IconCheck />You demand tried and tested data and signals that identify when you need to buy or sell.</li>
            <li><IconCheck />You yearn for the trading data that the banks have, and how they trade their multi-billion dollar accounts.​</li>
            <li><IconCheck />You want to learn how to be able to capture the same trading opportunities as the masters of the markets, taught by time tested financial markets experts.​</li>
            <li><IconCheck />You yearn to unleash the power of market cycles and their trade predicting capabilities.</li>
          </ul>
        </article>
      </div>
      <ButtonCTA href='#form-request-course-information' fontSize={1.6} round grow shadow>Request course information NOW</ButtonCTA>
    </section>

  )
}

export default OurCourses
