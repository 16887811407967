import React, { useState } from 'react'
import './team.scss'
import { ButtonCTA } from './utils/buttons'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'


const OurCourses = () => {
  const [daleText, setdaleText] = useState(false)
  const [rayText, setrayText] = useState(false)
  const data = useStaticQuery(graphql`
    {
      dale:file(relativePath: {eq: "Dale_Gaucas.jpg"}) {
        childImageSharp {
          fluid(quality:100){
            ...GatsbyImageSharpFluid_withWebp
          } 
        }
      }
            raymond:file(relativePath: {eq: "Raymond_Gilmour.png"}) {
        childImageSharp {
          fluid(quality:100){
            ...GatsbyImageSharpFluid_withWebp
          } 
        }
      }


    }
  `)
  const {
    dale: { childImageSharp: { fluid: dale } },
    raymond: { childImageSharp: { fluid: raymond } },
  } = data
  // return <h1>hello</h1>
  return (
    <section className='row team'>
      <h2 className='header2' > Meet the course <span className='orange'>creators</span> and <span className='orange'>mentors</span></h2>

      {/* Wrapper 1 */}
      <div className="team__wrapper">
        <article className="team__col txt-centre">
          <Img fluid={dale} />
          <span className="team__para--reviewer">Dale Gaucas</span>
          <p className="team__para--post">Managing Director &amp; Visionary</p>

        </article>
        <article className="team__col">
          <p className="team__para">
            This stuff has transformed my trading. I’ve been trading live all the time. My weekly profit has gone way over what I dreamed it to be over the last 3 weeks. You guys know your stuff! Thanks to you all!!
            </p>
          {
            daleText ? <React.Fragment>

              <p className='team__para'>Dale is the mind behind the IWT 4DYNAMICS trading system, and is the author of a number of successful publications including the books accelerate your trading, the clarity code, and the millionaire trader in you.</p>
              <p className='team__para'>Dales passion has always been in teaching and helping people to align with their highest dreams. It was out of both his desire to help people and his love for trading that Infinite Wealth Trading was born.</p>
              <p className='team__para'>Dale comes into his own in with the mindset development in trading. He has positioned himself as a thought leader in the world of trading.</p>

              <p className='team__para'>Emotional mastery is key foundation of trading. Dale has the expertise and experiential learning to ensure you that you become a master of yours.</p>
            </React.Fragment> : ""
          }
          <a onClick={() => { setdaleText(!daleText) }}>{daleText ? "[Less]" : "[Read More]"}</a>
        </article>
      </div>

      {/* Wrapper 2 */}
      <div className="team__wrapper">
        <article className="team__col txt-centre displace">
          <Img fluid={raymond} />
          <span className="team__para--reviewer">Raymond Gilmour</span>
          <p className="team__para--post">Expert technical analyst, COT data specialist and large volume trader</p>
        </article>
        <article className="team__col displace">
          <p className="team__para">
            Raymond Gilmour is a vastly experienced trader specialising in technical analysis, seasonality, and the commitments of traders reports. Raymond has been navigating the financial markets now for 18 years.
            </p>

          {
            rayText ? <React.Fragment>
              <p className="team__para">Through this time Raymond has guided countless students to success in the markets, training both new and advanced traders to their trading success.</p>
              <p className="team__para">He is a talented trading mentor with admirable experience in managing profitable trades.</p>
              <p className="team__para">As head trader trainer, he loves to inspire, give confidence and invest time with people who want to learn the technical concepts of trading with the sole aim of helping them become successful and profitable traders.</p>

            </React.Fragment> : ""
          }
          <a onClick={() => { setrayText(!rayText) }}>{rayText ? "[Less]" : "[Read More]"}</a>
        </article>
      </div>


      <div className="row">
        <ButtonCTA href='#form-request-course-information' fontSize={1.6} round grow shadow>Request course information NOW</ButtonCTA>

      </div>
    </section>

  )
}

export default OurCourses
