import React from 'react'
import './discover.scss'
import { ButtonCTA } from './utils/buttons'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { AppContext } from '../context/AppContext'
import { useContext } from "react"



const OurCourses = () => {
  const { dispatch } = useContext(AppContext);
  const data = useStaticQuery(graphql`
    {
      hero:file(relativePath: {eq: "poster.jpg"}) {
        childImageSharp {
          fluid(quality:50){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img2:file(relativePath: {eq: "discover__image2.jpg"}) {
        childImageSharp {
          fluid(quality:50){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img3:file(relativePath: {eq: "discover__image3.jpg"}) {
        childImageSharp {
          fluid(quality:50){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img4:file(relativePath: {eq: "discover__image4.jpg"}) {
        childImageSharp {
          fluid(quality:50){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      img5:file(relativePath: {eq: "discover__image5.jpg"}) {
        childImageSharp {
          fluid(quality:50){
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

    }
  `)
  const {
    hero: { childImageSharp: { fluid: hero } },
    img2: { childImageSharp: { fluid: img2 } },
    img3: { childImageSharp: { fluid: img3 } },
    img4: { childImageSharp: { fluid: img4 } },
    img5: { childImageSharp: { fluid: img5 } },
  } = data
  // return <h1>hello</h1>
  return (
    <section className='row discover'>
      <h2 className='header2' ><span className='orange'>Discover</span> how we've helped more people like you</h2>

      {/* Wrapper 1 */}
      <div className="discover__wrapper">
        <article className="discover__col">
          <a className='video-link'
            onClick={() => { dispatch({ type: "OPEN_VIDEO", payload: { isOpen: true, videoUrl: "https://videos.sproutvideo.com/embed/ac9cd8b61c1fe1c625/0ee3288fc91be02a?playerColor=ff5a28&autoplay=true&lightbox=true" } }) }} >
            <Img fluid={hero} />
          </a>
          <p className="discover__para">
            This stuff has transformed my trading. I’ve been trading live all the time. My weekly profit has gone way over what I dreamed it to be over the last 3 weeks. You guys know your stuff! Thanks to you all!!
            </p>
          <span className="discover__para--reviewer">
            Dr Richard Malloch
            </span>
        </article>
        <article className="discover__col">
          <a className='video-link'
            onClick={() => { dispatch({ type: "OPEN_VIDEO", payload: { isOpen: true, videoUrl: "https://videos.sproutvideo.com/embed/119cd8b61c1fe6c898/7cf5b0fe5ce5a86e?playerColor=ff5a28&autoplay=true&lightbox=true" } }) }} >
            <Img fluid={img2} />
          </a>
          <p className="discover__para">
            Previously it was basically getting a bit of money to get you going and then losing your money. My returns now are over 7.4% a month and I’m actually pushing to 8.
            </p>
          <span className="discover__para--reviewer">Dave Mckenna</span>
        </article>
      </div>

      {/* Wrapper 2 */}
      <div className="discover__wrapper discover__wrapper--2">
        <article className="discover__col">
          <a className='video-link'
            onClick={() => { dispatch({ type: "OPEN_VIDEO", payload: { isOpen: true, videoUrl: "https://videos.sproutvideo.com/embed/799cd8b61c1fe7c1f0/122ff64ba80e1b48?playerColor=ff5a28&autoplay=true&lightbox=true" } }) }} >
            <Img fluid={img3} />
          </a>
          <p className="discover__para">
            When i first started, before i got involved in this there were no monthly returns, now we’ve created 80% growth since working together.
            </p>
          <span className="discover__para--reviewer">John Rankins</span>
        </article>

        <article className="discover__col">
          <a className='video-link'
            onClick={() => { dispatch({ type: "OPEN_VIDEO", payload: { isOpen: true, videoUrl: "https://videos.sproutvideo.com/embed/449cd8b61c1fe6c9cd/c0a243448b1ffa05?playerColor=ff5a28&autoplay=true&lightbox=true" } }) }} >
            <Img fluid={img4} />
          </a>
          <p className="discover__para">
            It makes me feel pretty good because first of all, I need to do something but I’m doing something that gives me better life and more time with my partner.
            </p>
          <span className="discover__para--reviewer">Henk Niermans</span>
        </article>

        <article className="discover__col">
          <a className='video-link'
            onClick={() => { dispatch({ type: "OPEN_VIDEO", payload: { isOpen: true, videoUrl: "https://videos.sproutvideo.com/embed/ac9cd8b61c1fe7c025/87246fe83a400cb3?playerColor=ff5a28&autoplay=true&lightbox=true" } }) }} >
            <Img fluid={img5} />
          </a>
          <p className="discover__para">
            The programme’s really helped me by the fact that I didn’t have any background in trading whatsoever. Once I started immersing myself in the teachings of Dale, the Masterminds, and the course, it went all the way up.
            </p>
          <span className="discover__para--reviewer">Julian Zalums</span>
        </article>

      </div>
      <div className="row">
        <ButtonCTA href='#form-request-course-information' fontSize={1.6} round grow shadow>Request course information NOW</ButtonCTA>
      </div>
    </section>

  )
}
export default OurCourses
