import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'

import './pitch.scss'

const Pitch = () => {
  const data = useStaticQuery(graphql`
    {
      widget1:file(relativePath: {eq: "widget1.png"}) {
        childImageSharp {
          fixed(width:50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      widget2:file(relativePath: {eq: "widget2.png"}) {
        childImageSharp {
          fixed(width:50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      widget3:file(relativePath: {eq: "widget3.png"}) {
        childImageSharp {
          fixed(width:50) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `)
  const {
    widget1: { childImageSharp: { fixed: widget1 } },
    widget2: { childImageSharp: { fixed: widget2 } },
    widget3: { childImageSharp: { fixed: widget3 } } } = data

  return (
    <section className="pitch">
      <div className="row pitch__wrapper">
        <article className="pitch__widget">
          <Img fixed={widget1} />
          <h3>Simplify your Understanding </h3>
          <p>Get a clear and proven process to execute winning trades.</p>
        </article>

        <article className="pitch__widget">
          <Img fixed={widget2} />
          <h3>Learn How We Get Excellent Returns</h3>
          <p>You will work alongside expert traders who will be available 24/7.</p>
        </article>


        <article className="pitch__widget">
          <Img fixed={widget3} />
          <h3>Become a Master Trader</h3>
          <p>Replicate our systems and get the returns you need.</p>
        </article>
      </div>

    </section>
  )
}

export default Pitch
