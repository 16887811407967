import React, { useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import LazyLoad from 'react-lazyload'
import Img from 'gatsby-image'
import './footer.scss'
import { IconFacebook, IconTwitter, IconLinkedIn, IconGlobe, IconEnvelope, IconLocationArrow, IconPhone } from "./utils/fa-Icons"


const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      footerLogo:file(relativePath: {eq: "footer-logo.png"}) {
        childImageSharp {
          fluid(quality:100){
            ...GatsbyImageSharpFluid_withWebp
          } 
        }
      }
    }
  `)
  const { footerLogo: { childImageSharp: { fluid: logo } } } = data;
  return (
    <footer className='footer'>
      <section className="row footer__wrapper">
        <article className='footer__col'>
          <a href='https://infinitewealthtrading.com/'>
            <Img fluid={logo} className="footer__logo" style={{ margin: 0 }} />
          </a>
          <p className='footer__para'>Accelerate your trading and create financial abundance</p>
          <div className="footer__social">
            <a className="footer__social-icons" rel="noopener" target='_blank' href="https://www.facebook.com/infinitewealthtrading" ><IconFacebook /></a>
            <a className="footer__social-icons" rel="noopener" target='_blank' href="" ><IconTwitter /></a>
            <a className="footer__social-icons" rel="noopener" target='_blank' href="" ><IconLinkedIn /></a>
            <a className="footer__social-icons" rel="noopener" target='_blank' href=""><IconGlobe /></a>
          </div>
        </article>
        <article className='footer__col footer__col--links'>
          <h3 className='footer__h3'>Quick links</h3>
          <ul>
            <a href="https://infinitewealthtrading.com/contact-us/"><li>Contact Us</li></a>
            <a href="https://infinitewealthtrading.com/privacy-policy/"><li>Privacy Policy</li></a>
            <a href="https://infinitewealthtrading.com/risk-disclaimer/"><li>Risk Disclaimer</li></a>
            <a href="https://infinitewealthtrading.com/privacy-policy/"><li>Attributions</li></a>
          </ul>
        </article>
        <article className='footer__col footer__col--contact'>
          <h3 className='footer__h3'>Contact Information</h3>
          <ul>
            <a href='https://goo.gl/maps/V8zRaYwD9NsyE5wq6'><li><IconLocationArrow />Holland house, Hatters farm, Middleton, Manchester, M24 6XL </li></a>
            <a href="tel:+44%20161%20710%202771"><li><IconPhone />+44 161 710 2771</li></a>
            <a href="mailto:info@infinitewealthtrading.com"><li><IconEnvelope />info@infinitewealthtrading.com</li></a>
          </ul>
        </article>
        <article className='footer__col'>
          <h3 className='footer__h3'>Location</h3>

          <LazyLoad>
            <iframe style={{ width: '100%' }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2369.28821226212!2d-2.2034742845267035!3d53.570472680026555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bba439746c819%3A0x3ab60237e88f30b8!2sHatters%20Farm!5e0!3m2!1sen!2suk!4v1589295869382!5m2!1sen!2suk" frameBorder="0" allowFullScreen="" tabIndex="0" aria-hidden='false'></iframe>
          </LazyLoad>
        </article>
      </section>
      <section className="footer__copyright">
        <div className="row">

          <p>Infinite Wealth Trading &copy; 2020. All Rights Reserved.</p>
        </div>
      </section>
    </footer >
  )
}

export default Footer
