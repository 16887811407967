import React from 'react'
import Layout from '../components/Layout'
import VideoPlayer from '../components/VideoPlayer'
import { AppProvider } from '../context/AppContext'

const index = () => {
  return <>
    <AppProvider>
      <VideoPlayer></VideoPlayer>
      <Layout />
    </AppProvider>
  </>
}

export default index
