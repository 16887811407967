import React, { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import ModalVideo from 'react-modal-video'
import './video-player.scss'
import { AppContext } from '../context/AppContext'
import { useContext } from 'react'



const VideoPlayer = () => {
  const { state, dispatch } = useContext(AppContext);

  return state['videoUrl'] ? <ModalVideo
    channel='custom'
    url={encodeURI(state['videoUrl'])}
    autoplay
    isOpen={state['isOpen']}
    onClose={() => dispatch({ type: "CLOSE_VIDEO", payload: { isOpen: false, } })} />
    : <></>

}
export default VideoPlayer
