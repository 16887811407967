import React from 'react'
import Header from './Header'
import OurCourses from './OurCourses'
import Discover from './Discover'
import BackgroundSection from './BackgroundSection'
import Pitch from './Pitch'
import Team from './Team';
import './layout.scss'
import Footer from './Footer'
import SEO from './seo'

const Layout = () => <React.Fragment>
  <BackgroundSection>
    <Header />
  </BackgroundSection>
  <OurCourses />
  <Discover />
  <Pitch />
  <Team />
  <Footer />
  <SEO />
</React.Fragment>

export default Layout
