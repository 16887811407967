import React, { useEffect, useRef, useState } from 'react'
import './heroform.scss'
import { ButtonCTA } from './utils/buttons';
import IntlTelInput from './PhoneInput';
import './PhoneInput/css/main.css';
import axios from 'axios'
import isEmail from 'validator/lib/isEmail'
import LazyLoad from 'react-lazyload'
import ThankYou from './ThankYou';

const HeroForm = () => {
  const formRef = useRef()


  const initialFormData = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
  }

  const [formTouched, setformTouched] = useState({
    firstname: false,
    lastname: false,
    email: false,
    phone: false,
    submit: false
  })
  const [formSuccess, setformSuccess] = useState(false)
  const [errors, setErrors] = useState({
    firstname: '* required',
    lastname: '* required',
    email: '* required',
    phone: '* required'
  })
  const [formData, setFormData] = useState(initialFormData)

  const checkPhoneNumber = async () => {
    let varified = false;
    console.log("Checking phone number:" + formData.phone)
    const res = await axios.get(`https://apilayer.net/api/validate?access_key=20094a162d6e7cb396b24f2dc8ec1a85&number=${encodeURI(formData.phone)}`)

    if (!errors.firstname && !errors.lastname && !errors.email) {

      if (res["data"]["valid"]) {
        console.log("Phone Number verified")
        varified = true;
        setErrors({ ...errors, phone: '' })
      } else {
        setErrors({ ...errors, phone: 'Invalid phone number.' })
      }
    } else {
      setErrors({ ...errors, phone: "Please complete all fields" })

    }
    return varified;
  }
  const validatefirstname = (fieldname) => {
    setformTouched({ ...formTouched, [fieldname]: true })
    let errMsg = ""
    if (fieldname === "email") {
      if (formData[fieldname] === "") {
        errMsg = fieldname + " cannot be empty.";
      } else if (!isEmail(formData[fieldname])) {
        errMsg = "Invalid email"
      } else {
        errMsg = ""
      }
    } else {
      if (formData[fieldname] === "") {
        errMsg = fieldname + " cannot be empty.";
      } else if (/[^a-zA-Z -]/.test(formData[fieldname])) {
        errMsg = fieldname + " must contain only Alphabet characters.";
      } else if (formData[fieldname].length <= 2) {
        errMsg = fieldname + " is too short";
      } else {
        errMsg = "";
      }
    }
    setErrors({ ...errors, [fieldname]: errMsg })
  }

  const _getCookie = (cname) => {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const handleSubmit = async () => {
    setformTouched({ ...formTouched, 'submit': true })
    const varified = await checkPhoneNumber();
    console.log(varified)
    if (varified) {
      if (!errors.firstname && !errors.lastname && !errors.phone && !errors.email) {
        var url = `https://api.hsforms.com/submissions/v3/integration/submit/7503394/3d3fde2f-b0da-4941-932b-c66ddd0b1c71`
        // Example request JSON:
        var data = {
          "fields": [
            {
              "name": "email",
              "value": formData.email
            },
            {
              "name": "firstname",
              "value": formData.firstname
            },
            {
              "name": "lastname",
              "value": formData.lastname
            },
            {
              "name": "phone",
              "value": formData.phone
            }
          ],
          "context": {
            "hutk": `${_getCookie('hubspotutk')}`, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
            "pageUri": "https://www.infinitewealthtradingcourse.com/",
            "pageName": "IWT Landing Page"
          },
        }
        axios.post(url, data).then(res => {
          if (res['status'] === 200 && res['data']['inlineMessage']) {
            setformTouched({ firstname: false, phone: false, email: false, submit: false, lastname: true })
            setformSuccess(true)
            setFormData(initialFormData)
            setErrors(
              {
                firstname: '* required',
                lastname: '* required',
                email: '* required',
                phone: '* required'

              })
          }
        })
      }
    } else {
      setErrors({ ...errors, phone: 'Phone number was not validated' })
      setformSuccess(false)
    }

  }



  // }


  return (

    <div className="formWrapper" id='#container__form-request-course-information'>


      {
        formSuccess ?
          <ThankYou />
          : <form className="form" id="form-request-course-information" ref={formRef}>
            <h2>Request more information on our <span className='orange'>FOREX</span> trading courses</h2>
            <div className="form__group">
              <label htmlFor="firstname" className="form__label">First Name: </label>
              <input
                name="firstname"
                id='firstname'
                type='text'
                className="form__input"
                placeholder='First Name'
                value={formData.firstname}
                onChange={(e) => {
                  setFormData({ ...formData, firstname: e.target.value })
                }}
                onBlur={(e) => {
                  validatefirstname('firstname')
                }}

              ></input>
              {formTouched.submit | formTouched.firstname ? <span className='form__error'>{errors.firstname}</span> : null}
            </div>
            <div className="form__group">
              <label htmlFor="lastname" className="form__label">Last Name: </label>

              <input
                id='lastname'
                name='lastname'
                type='text'
                className="form__input"
                placeholder='Last Name'
                value={formData.lastname}
                onChange={(e) => {
                  setFormData({ ...formData, lastname: e.target.value })
                }}
                onBlur={() => validatefirstname('lastname')}
              >
              </input>
              {formTouched.lastname | formTouched.submit ? <span className='form__error'>{errors.lastname}</span> : null}

            </div>
            <div className="form__group">
              <label htmlFor="email" className="form__label">Email Address </label>
              <input
                id='email'
                name='email'
                type='text'
                className="form__input"
                placeholder='Email Address'
                onChange={(e) => {
                  setFormData({ ...formData, email: e.target.value })
                }}
                onBlur={() => validatefirstname('email')}
                value={formData.email}
              >
              </input>

              {formTouched.submit | formTouched.email ? <span className='form__error'>{errors.email}</span> : null}

            </div>
            <LazyLoad>

              <IntlTelInput
                formatOnInit={false}
                fieldName="phone"

                value={formData.phone}
                placeholder="Telephone Number"
                preferredCountries={['gb', 'us']}
                // allowDropdown={false}
                nationalMode={false}
                autoHideDialCode={false}
                // onlyCountries={['gb', 'us']}
                onPhoneNumberChange={(status, value, countryData, number, id) => {
                  setFormData({ ...formData, phone: value })
                  setformTouched({ ...formTouched, phone: true })
                }}

                onPhoneNumberBlur={async (status, value, countryData, number, id) => {
                  if (!status) {
                    setErrors({ ...errors, phone: "Invalid phone" })
                  } else {
                    setErrors({ ...errors, phone: "" })

                  }
                }
                }

                containerClassName="intl-tel-input form__group"
                inputClassName="form__input"
              />
            </LazyLoad>
            <div>
              {formTouched.submit ? <span className='form__error'>{errors.phone}</span> : null}
            </div>
            <ButtonCTA role="submit" onClick={handleSubmit} href='#form-request-course-information' style={{ marginTop: '40px', pointer: 'cursor', fontSize: '1.4em' }}>Request course information NOW</ButtonCTA>
          </form>
      }
    </div>

  )
}

export default HeroForm
