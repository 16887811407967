import React, { useReducer } from 'react'
import { reducer } from './reducer'

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    isOpen: true,
    videoUrl: ""
  })
  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  )
}

export { AppProvider, AppContext };