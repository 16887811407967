import React, { useEffect } from 'react'

const ThankYou = () => {
  useEffect(() => {
    if (window['gtag']) {
      window['gtag']('event', 'conversion', { 'send_to': 'AW-646630234/YvZKCMDEtM4BENqWq7QC' });
    } else {
      console.log("GTM is not initialised.")
    }
    return () => {
    }
  }, [])
  return (
    <section className="form__thank-you">
      <h2 className="form__thank-you--h2">Thank You</h2>
      <h3>What Happens next? </h3>
      <p id="ty-text" className="form__thank-you--para">We have received your enquiry for more course information.
      We will be calling you shortly to arrange a demonstration of our best in class trading courses
      One of our mentors will contact you to give you everything that you need so you can achieve your trading goals.
          We look forward to helping you become a master trader.</p>
    </section>
  )
}

export default ThankYou
