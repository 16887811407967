import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'


const UnstyledBackgroundSection = ({ children }) => {
  const data = useStaticQuery(graphql`{
        background:file(relativePath: {eq: "background_desktop.webp"}) {
          childImageSharp {
            fluid(maxHeight: 10){
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        backgroundDesktop:file(relativePath: {eq: "background_desktop.webp"}) {
          childImageSharp {
            fluid(quality: 100, maxWidth:1800){
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
  }`)
  const {
    background: { childImageSharp: { fluid: backgroundFluid } },
    backgroundDesktop: { childImageSharp: { fluid: backgroundDesktopFluid } }
  } = data
  const sources = [backgroundFluid, {
    ...backgroundDesktopFluid,
    media: `(min-width: 491px)`
  }]
  return (
    <BackgroundImage fluid={sources} loading='eager'>
      {children}
    </BackgroundImage>
  )
}
const BackgroundSection = styled(UnstyledBackgroundSection)`
  background-color: #13132f;
  width: 100%;
  background-position: top left;
  background-repeat: repeat-y;
  background-size: cover;
`

export default BackgroundSection