import styled from 'styled-components'

export const ButtonCTA = styled.a`
text-decoration: none;
  text-align: center;
  display: inline-block;
  margin: 20px auto;
  font-family:inherit;
  font-size: ${props => props.fontSize}em;
  border: none;
  background-color: rgb(255, 90, 40);
  color: #ffffff;
  border-radius: ${props => props["round"] ? "50px" : "2px"};
  padding:  1em 1.2em;
  cursor: pointer;
  outline: none;
  box-shadow: ${props => props["shadow"] ? " 0px 0px 10px 0px rgba(0,0,0,0.5)" : ""};
  transition: transform 0.5s;
  :hover{
    transform:${props => props["grow"] ? "scale(1.1)" : ""};
    background-color: #FF3c00;
  }
`
export const ButtonPhone = styled.button`
  display:flex;
  justify-content: center;
  align-items: center;
  font-family:inherit;
  font-size: ${props => props.fontSize}em;
  border: none;
  background-color: rgb(255, 90, 40);
  border-radius: ${props => props["round"] ? "50px" : "2px"};
  padding:  1.2em;
  cursor: pointer;
  outline: none;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
  transition: transform 0.5s;
  :hover{
    transform:${props => props["grow"] ? "scale(1.1)" : ""};
  }
`
